<template>
  <div class="component-main">
    <div class="outer">
      <div class="text half">
        <h1 class="font-nanum" :class="[store.state.site.lang]">
          <template v-if="store.state.site.lang === 'ko'"> <b class="color-point">퍼블레싱</b> ['pʌblesɪŋ]<br />: 명령어로 UI를 그리는 도구</template>
          <template v-else> <b class="color-point">Publessing</b> ['pʌblesɪŋ]<br />: A tool to draw UI using commands</template>
        </h1>
        <h2 class="font-coding">
          <template v-if="store.state.account.login">
            <template v-if="store.state.site.lang === 'ko'">
              "create startup" 을 입력 후<br />
              "open startup" 을 입력해주세요
            </template>
            <template v-else>
              Enter "create startup" <br />
              then "open startup"
            </template>
          </template>
          <template v-else>
            <template v-if="store.state.site.lang === 'ko'">
              <div>아래의 콘솔 열기 버튼 클릭 후</div>
              "join" 혹은 "login" 을 입력해주세요
            </template>
            <template v-else>
              Click OPEN CONSOLE button below<br />
              then enter "join" or "login"
            </template>
          </template>
        </h2>
        <div class="buttons">
          <button class="btn btn-point" @click="callback('toggle-console')">
            <template v-if="store.state.site.lang === 'ko'">
              <span>콘솔 </span>
              <span v-if="visible">닫기</span>
              <span v-else>열기</span>
            </template>
            <template v-else>
              <span v-if="visible">CLOSE </span>
              <span v-else>OPEN </span>
              <span>CONSOLE</span>
            </template>
          </button>
          <router-link :to="`/${store.state.site.lang}/intro`" class="btn btn-point-line">{{ store.state.site.lang === "ko" ? "도구 소개" : "VIEW MORE" }}</router-link>
        </div>
      </div>
      <div class="image half">
        <img src="/assets/img/image.main.svg" alt="Chracter for main page" />
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";

export default {
  props: {
    visible: Boolean,
    callback: Function,
    load: Function,
  },
  setup() {
    const store = useStore();

    return {
      store,
    };
  },
};
</script>

<style lang="scss" scoped>
.component-main {
  position: relative;
  width: 100%;

  .outer {
    padding: 170px 0 298px 0;

    .half {
      width: 50%;
      float: left;
    }

    h1 {
      font-size: 27px;
      position: relative;
      font-weight: normal;
      margin-bottom: 25px;
      line-height: 200%;

      b {
        font-size: 32px;
        font-weight: 900;
      }

      &.en {
        font-size: 25px;

        b {
          font-size: 35px;
        }
      }
    }

    h2 {
      font-size: 21px;
      position: relative;
      margin-bottom: 25px;
      line-height: 190%;
      color: #212121;
    }

    .buttons {
      margin: 0 -10px;

      .btn {
        margin: 10px;
        height: 50px;
        line-height: 50px;
        font-size: 15px;
        padding: 0;
        width: 160px;
        box-shadow: none;
        outline: 0;
        border-radius: 0;
        font-weight: normal;
      }
    }

    &:after {
      clear: both;
      display: table;
      content: " ";
    }
  }

  &[size="sm"] {
    .outer {
      padding: 100px 0;
    }
  }

  &[size="sm"],
  &[size="xs"],
  &[size="xxs"] {
    .outer {
      > div {
        width: 100%;
        text-align: center;
      }

      .image {
        margin-top: 50px;
        text-align: center;

        img {
          max-width: 420px;
        }
      }
    }
  }

  &[size="xs"],
  &[size="xxs"] {
    .outer {
      padding: 75px 0;
    }
  }

  &[size="xxs"] {
    .outer {
      h1 {
        font-size: 21px;
        line-height: 250%;
      }

      .image {
        img {
          max-width: 300px;
        }
      }
    }
  }

  @media (max-width: 1139px) {
    .outer {
      h2 {
        display: none;
      }

      .buttons {
        button {
          display: none;
        }
      }
    }
  }
}
</style>
